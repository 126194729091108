"use strict";

import Swal from 'sweetalert2';
export default class Toast {
    static message(icon, title, position = 'top-end', time = 2500) {
        let toast = Swal.mixin({
            toast: true,
            position,
            showConfirmButton: false,
            timer: time,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        toast.fire({icon, title});
    }

    static success(title, position = 'top-end', time = 2500) {
        this.message('success', title, position, time);
    }

    static error(title, position = 'top-end', time = 2500) {
        this.message('error', title, position, time);
    }

    static warning(title, position = 'top-end', time = 2500) {
        this.message('warning', title, position, time);
    }

    static info(title, position = 'top-end', time = 2500) {
        this.message('info', title, position, time);
    }

    static internalError() {
        this.error('Something went wrong! Please refresh page!');
    }
}