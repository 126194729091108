<script setup>
import {ref, onMounted, watch} from 'vue';
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

// Variables.
const model = ref(null);

// Props.
const props = defineProps({
  field: String,
  defaultValue: String
});

// Watchers.
watch(() => props.defaultValue, (newValue) => {
  model.value = newValue;
});

// Mounted.
onMounted(() => {
  model.value = props.defaultValue;
});
</script>

<template>
  <color-picker
      v-model:pureColor="model"
      :value="defaultValue"
      picker-type="chrome"
      :debounce=300
      format="hex"
      shape="square"
      use-type="pure"
      lang="En"
      theme="white"
      disable-alpha
      disable-history
      @pure-color-change="$emit('input', {field: field, value: $event})"
  />
</template>