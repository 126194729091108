<script setup>
import {computed, onMounted, ref, watch} from 'vue';
import Toast from "../../../js/components/common/toast";
import ColorPicker from "./ColorPicker.vue";

// Props.
const props = defineProps({
  websiteUrl: String,

  gameId: Number,
  serverId: Number,

  bannerTypesApiUrl: String,
  bannerBackgroundsApiUrl: String,
  viewServerUrl: String,
  viewServerBannerUrl: String
});

// Variables.
const root = ref(null);
const isLoading = ref(true);

const translations = {
  configuration: 'Configuration'.toUpperCase(),
  preview: 'Preview'.toUpperCase(),
  banner_type: 'Banner type',
  banner_background: 'Background',
  title_color: 'Title color',
  information_color: 'Information color',
  border: 'Border?',
  border_yes: 'Yes',
  border_no: 'No',
  border_color: 'Border color',
  gradient_top_color: 'Gradient top color',
  gradient_bottom_color: 'Gradient bottom color',
  graphic_axis_color: 'Graphic axis color',
  graphic_bars_color: 'Graphic bars color',
  banner_image: 'Banner image',
  bb_code: 'BBCode',
  html_code: 'HTML code',
  direct_link_code: 'Direct link',
};

// Banner type.
const selectedBannerType = ref(null);
const bannerTypes = ref([]);

// Banner background.
const selectedBannerBackground = ref(null);
const bannerBackgrounds = ref([]);
const cachedBannerBackgrounds = ref({});

// Border.
const supportsBorder = ref(false);
const hasBorder = ref(false);

// Gradient.
const supportsGradient = ref(false);

// Colors.
const titleColor = ref('#FFC200');
const informationColor = ref('#FFFFFF');

const borderColor = ref('#000000');

const gradientTopColor = ref('#000000');
const gradientBottomColor = ref('#000000');

const graphicAxisColor = ref('#FFFFFF');
const graphicBarsColor = ref('#FFC200');

// Computed.
const bannerTypesLength = computed(() => bannerTypes.value.length);
const displayBorderSection = computed(() => supportsBorder.value === true);
const displayGradientSection = computed(() => supportsGradient.value === true);

const bbcodeUrl = computed(() => {
  const parameters = generateUrlParameters();

  return `[URL=${props.viewServerUrl}][IMG]${props.viewServerBannerUrl}?${parameters}[/IMG][/URL]`;
});

const htmlCodeUrl = computed(() => {
  const parameters = generateUrlParameters();

  return `<a href="${props.viewServerUrl}" target="_blank"><img alt="${props.websiteUrl}" src="${props.viewServerBannerUrl}?${parameters}"/></a>`;
});

const directLinkUrl = computed(() => {
  const parameters = generateUrlParameters();

  return `${props.viewServerBannerUrl}?${parameters}`;
});

const bannerImageUrl = computed(() => {
  const parameters = generateUrlParameters();

  return `${props.viewServerBannerUrl}?${parameters}`;
})

// Methods.
function onColorUpdated(data) {
  isLoading.value = true;

  const field = data.field;
  const value = data.value;

  switch (field) {
    case 'titleColor':
      titleColor.value = value;
      break;
    case 'informationColor':
      informationColor.value = value;
      break;
    case 'borderColor':
      borderColor.value = value;
      break;
    case 'gradientTopColor':
      gradientTopColor.value = value;
      break;
    case 'gradientBottomColor':
      gradientBottomColor.value = value;
      break;
    case 'graphicAxisColor':
      graphicAxisColor.value = value;
      break;
    case 'graphicBarsColor':
      graphicBarsColor.value = value;
      break;
  }
}

function fetchBannerTypes() {
  return fetch(props.bannerTypesApiUrl)
      .then(response => response.json())
      .then(data => {
        bannerTypes.value.push(...data);

        if (!data.length) {
          return;
        }

        selectedBannerType.value = data[0].slug;
      })
      .catch(() => Toast.internalError());
}

function fetchBannerBackgrounds() {
  if (cachedBannerBackgrounds.value[selectedBannerType.value]) {
    bannerBackgrounds.value.splice(0);
    bannerBackgrounds.value.push(...cachedBannerBackgrounds.value[selectedBannerType.value]);
    selectedBannerBackground.value = cachedBannerBackgrounds.value[selectedBannerType.value][0].slug;
    setupConfigurationVariables(cachedBannerBackgrounds.value[selectedBannerType.value][0]);

    return;
  }

  const parameters = new URLSearchParams({
    'game_id': props.gameId,
    'server_id': props.serverId,
    'banner_type': selectedBannerType.value
  }).toString();

  return fetch(`${props.bannerBackgroundsApiUrl}?${parameters}`)
      .then(response => response.json())
      .then(data => {
        bannerBackgrounds.value.splice(0);
        bannerBackgrounds.value.push(...data);

        if (!data.length) {
          selectedBannerBackground.value = null;
          setupConfigurationVariables({
            title_color: '#FFC200',
            information_color: '#FFFFFF',
            has_border: false,
            border_color: '#000000',
            has_gradient: false,
            gradient_top_color: '#000000',
            gradient_bottom_color: '#000000',
            graphic_axis_color: '#FFFFFF',
            graphic_bars_color: '#FFC200'
          });

          return;
        }

        selectedBannerBackground.value = data[0].slug;
        setupConfigurationVariables(data[0]);

        // Save banners to local cache.
        cachedBannerBackgrounds.value[selectedBannerType.value] = data;
      })
      .catch(() => Toast.internalError());
}

function onBannerTypeChange() {
  isLoading.value = true;

  fetchBannerBackgrounds();
}

function setupConfigurationVariables(data) {
  titleColor.value = data.title_color;
  informationColor.value = data.information_color;

  supportsBorder.value = data.has_border;
  borderColor.value = data.border_color;

  supportsGradient.value = data.has_gradient;
  gradientTopColor.value = data.gradient_top_color;
  gradientBottomColor.value = data.gradient_bottom_color;

  graphicAxisColor.value = data.graphic_axis_color;
  graphicBarsColor.value = data.graphic_bars_color;
}

function onBannerBackgroundChange() {
  isLoading.value = true;

  const data = cachedBannerBackgrounds.value[selectedBannerType.value].find(bannerBackground => bannerBackground.slug === selectedBannerBackground.value);

  if (undefined === data) {
    return;
  }

  setupConfigurationVariables(data);
}

function generateUrlParameters() {
  const parameters = new URLSearchParams({
    'bg': selectedBannerBackground.value,
    'title': titleColor.value,
    'information': informationColor.value,
    'has-border': hasBorder.value,
    'border': borderColor.value,
    'gradient-top': gradientTopColor.value,
    'gradient-bottom': gradientBottomColor.value,
    'graphic-axis': graphicAxisColor.value,
    'graphic-bars': graphicBarsColor.value
  });

  return parameters.toString();
}

function onBannerImageLoad() {
  setTimeout(() => {
    isLoading.value = false;
  }, 500);
}

function onCopy(code) {
  try {
    navigator.clipboard.writeText(code);
    Toast.success('Content copied to clipboard!');
  } catch (err) {
    Toast.error('Copy failed!');
  }
}

// Watchers.
watch(selectedBannerType, async () => {
  onBannerTypeChange();
});

// Mounted.
onMounted(() => {
  fetchBannerTypes();

  if (!bannerTypesLength) {
    Toast.internalError();
  }
});
</script>

<template>
  <div class="row" ref="root">
    <div class="col-12 col-lg-6 mb-3 mb-lg-0">
      <h2 class="text-secondary" v-text="translations.configuration"/>
      <div class="card with-top-border shadow bg-body-tertiary rounded">
        <div class="card-body bg-dark mb-0 p-3" :class="{ 'opacity-50 disabled': isLoading }">
          <div class="row">
            <div class="col-6">
              <div class="mb-3">
                <label class="form-label" v-text="translations.banner_type"/>
                <select
                    required="required"
                    class="form-select"
                    v-model="selectedBannerType"
                >
                  <option
                      v-for="bannerType in bannerTypes"
                      :value="bannerType.slug"
                      :key="bannerType.slug"
                      v-text="bannerType.name"
                  />
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label class="form-label" v-text="translations.banner_background"/>
                <select
                    required="required"
                    class="form-select"
                    v-model="selectedBannerBackground"
                    @change="onBannerBackgroundChange"
                >
                  <option
                      v-for="bannerBackground in bannerBackgrounds"
                      :value="bannerBackground.slug"
                      :key="bannerBackground.slug"
                      v-text="bannerBackground.name"
                  />
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label class="form-label" v-text="translations.title_color"/>
                <div>
                  <ColorPicker field="titleColor" :default-value="titleColor" v-on:input="onColorUpdated"/>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label class="form-label" v-text="translations.information_color"/>
                <div>
                  <ColorPicker field="informationColor" :default-value="informationColor" v-on:input="onColorUpdated"/>
                </div>
              </div>
            </div>
            <div class="col-6" :class="{ 'd-none': !displayBorderSection }">
              <div class="mb-3">
                <label class="form-label" v-text="translations.border"/>
                <select required="required" class="form-select" v-model="hasBorder">
                  <option value="false" v-text="translations.border_no"/>
                  <option value="true" v-text="translations.border_yes"/>
                </select>
              </div>
            </div>
            <div class="col-6" :class="{ 'd-none': !displayBorderSection }">
              <div class="mb-3">
                <label class="form-label" v-text="translations.border_color"/>
                <div>
                  <ColorPicker field="borderColor" :default-value="borderColor" v-on:input="onColorUpdated"/>
                </div>
              </div>
            </div>
            <div class="col-6" :class="{ 'd-none': !displayGradientSection }">
              <div class="mb-3">
                <label class="form-label" v-text="translations.gradient_top_color"/>
                <div>
                  <ColorPicker field="gradientTopColor" :default-value="gradientTopColor" v-on:input="onColorUpdated"/>
                </div>
              </div>
            </div>
            <div class="col-6" :class="{ 'd-none': !displayGradientSection }">
              <div class="mb-3">
                <label class="form-label" v-text="translations.gradient_bottom_color"/>
                <div>
                  <ColorPicker field="gradientBottomColor" :default-value="gradientBottomColor" v-on:input="onColorUpdated"/>
                </div>
              </div>
            </div>
            <div class="col-6 js-graphic_section">
              <div class="mb-3">
                <label class="form-label" v-text="translations.graphic_axis_color"/>
                <div>
                  <ColorPicker field="graphicAxisColor" :default-value="graphicAxisColor" v-on:input="onColorUpdated"/>
                </div>
              </div>
            </div>
            <div class="col-6 js-graphic_section">
              <div class="mb-3">
                <label class="form-label" v-text="translations.graphic_bars_color"/>
                <div>
                  <ColorPicker field="graphicBarsColor" :default-value="graphicBarsColor" v-on:input="onColorUpdated"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <h2 class="text-secondary" v-text="translations.preview"/>
      <div class="card with-top-border shadow bg-body-tertiary rounded">
        <div class="card-body bg-dark mb-0 p-3" :class="{ 'opacity-50 disabled': isLoading }">
          <div class="row">
            <div class="col-12">
              <div class="text-left">
                <label class="form-label" v-text="translations.banner_image"/>
                <img
                    class="img-fluid d-block mx-auto"
                    :alt="{websiteUrl}"
                    :class="{'d-none': isLoading}"
                    :src="bannerImageUrl"
                    @load="onBannerImageLoad"
                />
                <div v-show="isLoading">
                  <i class="fas fa-spin fa-spinner fa-2x text-danger d-block mx-auto"/>
                </div>
              </div>
            </div>
            <div class="col-12 mt-3">
              <div class="form-group">
                <label class="form-label" for="bbcode" v-text="translations.bb_code"/>
                <div class="input-group">
                  <input id="bbcode" type="text" class="form-control" :value="bbcodeUrl" readonly="readonly"/>
                  <button class="btn btn-outline-danger" type="button" @click="onCopy(bbcodeUrl)">
                    <i class="fa fa-copy"></i>
                  </button>
                </div>
              </div>
              <div class="form-group mt-3">
                <label class="form-label" for="html_code" v-text="translations.html_code"/>
                <div class="input-group">
                  <input id="html_code" type="text" class="form-control" :value="htmlCodeUrl" readonly="readonly"/>
                  <button class="btn btn-outline-danger" type="button" @click="onCopy(htmlCodeUrl)">
                    <i class="fa fa-copy"></i>
                  </button>
                </div>
              </div>
              <div class="form-group mt-3">
                <label class="form-label" for="direct_link" v-text="translations.direct_link_code"></label>
                <div class="input-group">
                  <input id="direct_link" type="text" class="form-control" :value="directLinkUrl" readonly="readonly"/>
                  <button class="btn btn-outline-danger" type="button" @click="onCopy(directLinkUrl)">
                    <i class="fa fa-copy"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  /* Hide color picker toggle that changes the code format. Class not tracked by IDE. Appears as unused. */
  .vc-input-toggle {
    display: none;
  }
</style>